import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  grievances: [],
  isLoading: false,
  isFileUploading: false,
  logs: [],
  isLogsLoading: false,
  selectedGrievances: {},
  totalGrievances: 0,
  assignableUsers: [],
};

const GrievancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_GRIEVANCES_LIST:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_GRIEVANCES_LIST:
      return {
        ...state,
        isLoading: false,
        grievances: action.payload.grievancesData,
        totalGrievances: action.payload.count,
      };
    case actionTypes.GET_GRIEVANCES_DETAILS:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_GRIEVANCES_DETAILS:
      return { ...state, isLoading: false, selectedGrievances: action.payload };
    case actionTypes.CLEAR_GRIEVANCES_DETIALS:
      return { ...state, selectedGrievances: {} };
    case actionTypes.GET_ASSIGNABLE_USERS:
      return { ...state, assignableUsers: action.payload };
    case actionTypes.GET_GRIEVANCES_LOGS:
      return { ...state, isLogsLoading: true };
    case actionTypes.FETCH_GRIEVANCES_LOGS:
      return { ...state, isLogsLoading: false, logs: action.payload };

    default:
      return { ...state };
  }
};

export default GrievancesReducer;
