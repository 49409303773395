import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  partner: [],
  isLoading: false,
  isFileUploading: false,
  logs: [],
  isLogsLoading: false,
  selectedPartner: {},
  totalPartner: 0,
  assignableUsers: [],
};

const PartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_PARTNER_LIST:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_PARTNER_LIST:
      return {
        ...state,
        isLoading: false,
        partner: action.payload.partnerData,
        totalPartner: action.payload.count,
      };
    case actionTypes.GET_PARTNER_DETAILS:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_PARTNER_DETAILS:
      return { ...state, isLoading: false, selectedPartner: action.payload };
    case actionTypes.CLEAR_PARTNER_DETIALS:
      return { ...state, selectedPartner: {} };
    case actionTypes.GET_ASSIGNABLE_PARTNER_USERS:
      return { ...state, assignableUsers: action.payload };
    case actionTypes.GET_PARTNER_LOGS:
      return { ...state, isLogsLoading: true };
    case actionTypes.FETCH_PARTNER_LOGS:
      return { ...state, isLogsLoading: false, logs: action.payload };

    default:
      return { ...state };
  }
};

export default PartnerReducer;
