import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import Login from "./login";
import calls from "./calls";
import dashboardReducer from "./dashboard";
import ManageStartupssteps from "./startupstages";
import LoadingStateReducer from "./loading-state";
import StagesReducer from "./stages";
import RolesPermissionReducer from "./rolesPermission";
import MentorsReducer from "./mentors";
import IncubatorsReducer from "./incubators";
import InvestorReducer from "./investor";
import PartnerReducer from "./partner";
import GrievancesReducer from "./grievances";
import BoosterKitsReducer from "./boosterKit";
import IncentiveReducer from "./incentives";
const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  logindata: Login,
  calls,
  dashboard: dashboardReducer,
  reportData: ManageStartupssteps,
  loadingState: LoadingStateReducer,
  stagesData: StagesReducer,
  rolesPermission: RolesPermissionReducer,
  mentors: MentorsReducer,
  incubators: IncubatorsReducer,
  investor: InvestorReducer,
  partner: PartnerReducer,
  grievances: GrievancesReducer,
  boosterKits: BoosterKitsReducer,
  incentives: IncentiveReducer,
});

export default rootReducer;
