import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  boosterKits: [],
  isLoading: false,
  isFileUploading: false,
  logs: [],
  isLogsLoading: false,
  totalBoosterKit: 0,
};

const BoosterKitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_BOOSTERKIT_LIST:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_BOOSTERKIT_LIST:
      return {
        ...state,
        isLoading: false,
        boosterKits: action.payload.boosterKitData,
        totalBoosterKit: action.payload.count,
      };
    default:
      return { ...state };
  }
};

export default BoosterKitsReducer;
