import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  calls: [],
  isLoading: false,
};

const callsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CALLS:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_CALLS:
      return { ...state, isLoading: false, calls: action.payload };
    case actionTypes.FILTER_CALLS:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_FILTER:
      return { ...state, isLoading: false, calls: action.payload };
    default:
      return { ...state };
  }
};

export default callsReducer;
