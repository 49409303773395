// import state from "sweetalert/typings/modules/state";

const initialState = {
  list: [],
  total: "",
  detailList: "",
  UserList: [],
};

const ManageStartupssteps = (state = initialState, action) => {
  // // console.log(action, "testingnewqq");
  switch (action.type) {
    case "STARTUP_REGISTRATIONS_RESPONSE":
      return {
        ...state,
        list: [...action.data.list],
        total: action.data.total,
      };
    case "STARTUP_REGISTRATION_DETAIL_RESPONSE":
      return {
        ...state,
        detailList: action.data.detailList,
      };
    case "MANAGE_ALLUSERS_RESPONSE":
      return {
        ...state,
        UserList: [...action.data.UserList],
        total: action.data.total,
      };
    case "PORTAL_ALLUSERS_RESPONSE":
      return {
        ...state,
        UserList: [...action.data.UserList],
        total: action.data.total,
      };

    case "DEPARTMENT_ALLUSERS_RESPONSE":
      return {
        ...state,
        UserList: [...action.data.UserList],
        total: action.data.total,
      };

    default:
      return state;
  }
};

export default ManageStartupssteps;
