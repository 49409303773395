import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  applications: [],
  isLoading: false,
  isFileUploading: false,
  logs: [],
  isLogsLoading: false,
};

const StagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_APPROVED_LIST:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_APPROVED_LIST:
      return { ...state, isLoading: false, applications: action.payload };
    case actionTypes.UPLOAD_WINNER_LIST:
      return { ...state, isFileUploading: true };
    case actionTypes.UPLOAD_WINNER_LIST_COMPLETE:
      return { ...state, isFileUploading: false };
    case actionTypes.CLEAR_APPLICATIONS:
      return { ...state, applications: [] };
    case actionTypes.GET_LOGS:
      return { ...state, isLogsLoading: true };
    case actionTypes.GET_LOGS_SUCCESS:
      return { ...state, isLogsLoading: false, logs: action.payload };

    default:
      return { ...state };
  }
};

export default StagesReducer;
