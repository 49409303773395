import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  incubators: [],
  isLoading: false,
  isFileUploading: false,
  logs: [],
  isLogsLoading: false,
  selectedIncubators: {},
  totalIncubators: 0,
  assignableUsers: [],
};

const IncubatorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_INCUBATORS_LIST:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_INCUBATORS_LIST:
      return {
        ...state,
        isLoading: false,
        incubators: action.payload.incubatorData,
        totalIncubators: action.payload.count,
      };
    case actionTypes.GET_INCUBATORS_DETAILS:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_INCUBATORS_DETAILS:
      return { ...state, isLoading: false, selectedIncubators: action.payload };
    case actionTypes.CLEAR_INCUBATORS_DETIALS:
      return { ...state, selectedIncubators: {} };
    case actionTypes.GET_ASSIGNABLE_INCUBATOR_USERS:
      return { ...state, assignableUsers: action.payload };
    case actionTypes.GET_INCUBATOR_LOGS:
      return { ...state, isLogsLoading: true };
    case actionTypes.FETCH_INCUBATOR_LOGS:
      return { ...state, isLogsLoading: false, logs: action.payload };

    default:
      return { ...state };
  }
};

export default IncubatorsReducer;
