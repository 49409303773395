import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
// import navigationConfig from "../../../../../configs/navigationConfig"
import SideMenuGroup from "./SideMenuGroup";
import { Badge } from "reactstrap";
import { ChevronRight } from "react-feather";
import { history } from "../../../../../history";
import { IconFont } from "react-contexify";
import * as Icon from "react-feather";
// import { icon } from "leaflet";
const currentUser = localStorage.getItem("token")
  ? localStorage.getItem("adminaccess")
  : "";
class SideMenuContent extends React.Component {
  constructor(props) {
    super(props);

    this.parentArr = [];
    this.collapsedPath = null;
    this.redirectUnauthorized = () => {
      history.push("/misc/not-authorized");
    };
  }
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    navigationConfig: [],
    tempArr: [],
  };

  handleGroupClick = (id, parent = null, type = "") => {
    let open_group = this.state.activeGroups;
    let active_group = this.state.currentActiveGroup;
    let temp_arr = this.state.tempArr;
    // Active Group to apply sidebar-group-active class
    if (type === "item" && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === "item" && parent !== null) {
      active_group = [];
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [];
        temp_arr.push(parent);
      }
      active_group = temp_arr.slice(0);
    } else if (type === "collapse" && parent === null) {
      temp_arr = [];
      temp_arr.push(id);
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0);
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      temp_arr = [];
    }

    if (type === "collapse") {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function (obj) {
          return active_group.indexOf(obj) === -1;
        });
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function (obj) {
            return !temp.includes(obj);
          });
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0);
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id);
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0);
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group,
    });
  };

  initRender = (parentArr) => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false,
    });
  };

  testing = (value, usertype) => {
    // // console.log(value, "valueqqqqq");
    if (value === "registrations") {
      localStorage.setItem("applsbmt", false);
    }
    if (
      usertype === "admin" ||
      usertype === "Associate" ||
      usertype === "SM" ||
      usertype === "GM"
    ) {
      localStorage.setItem("currentRole", usertype);
    }
  };

  componentDidMount() {
    this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
    if (true) {
      let navigationConfig = [
        {
          id: "home",
          title: (
            <span onClick={() => this.testing("dashboard")}>Dashboard</span>
          ),
          type: "item",
          icon: <Icon.Home size={20} />,
          navLink: "/Dashboard",
          permissions: [
            "admin",
            "Associate",
            "manageFunding",
            "GM",
            "MD",
            "SM",
            "FDA",
          ],
        },

        {
          type: "groupHeader",
          groupTitle: "ADMIN MODERATION",
          permissions: ["admin"],
        },
        {
          id: "startups",
          title: "Startups",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin"],
          children: [
            {
              id: "registrations",
              title: (
                <>
                  <span
                    style={{ width: "100%", float: "left" }}
                    onClick={() => this.testing("registrations", "admin")}
                  >
                    Registrations
                  </span>
                </>
              ),
              type: "item",
              navLink: "/startup-registrations?pageNo=1&pageSize=10",
              activeLink: "/startup-registrations",
              permissions: ["admin"],
            },
            {
              id: "incentive",
              title: "Incentives",
              type: "item",
              navLink:
                "/incentiveRegistration?pageNo=1&pageSize=10&moderation=admin",
              activeLink: "/incentiveRegistration",
              permissions: ["admin"],
            },
            {
              id: "booster",
              title: "Booster Kit",
              type: "item",
              navLink: "/boosterKits?pageNo=1&pageSize=10&moderation=admin",
              activeLink: "/boosterKits",
              permissions: ["admin"],
            },
          ],
        },
        {
          id: "mentors",
          title: "Mentors",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/mentorRegistration?pageNo=1&pageSize=10&moderation=admin",
              activeLink: "/mentorRegistration",
              permissions: ["admin", "Associate"],
            },
          ],
        },
        {
          id: "incubators",
          title: "Incubators",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/incubatorsRegistration?pageNo=1&pageSize=10&moderation=admin",
              activeLink: "/incubatorsRegistration",
              permissions: ["admin", "Associate"],
            },
          ],
        },
        {
          id: "partner",
          title: "Partner",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/partnerRegistration?pageNo=1&pageSize=10&moderation=admin",
              activeLink: "/partnerRegistration",
              permissions: ["admin", "Associate"],
            },
          ],
        },
        {
          id: "Investor",
          title: "Investor",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/investorRegistration?pageNo=1&pageSize=10&moderation=admin",
              activeLink: "/investorRegistration",
              permissions: ["admin", "Associate"],
            },
          ],
        },
        {
          id: "AdminGrievances",
          title: "Grievances",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin"],
          children: [
            {
              id: "AdminGrievances1",
              title: "Applications",
              type: "item",
              icon: <Icon.List size={20} />,
              navLink:
                "/grievancesRegistration?pageNo=1&pageSize=10&moderation=admin",
              permissions: ["admin", "Associate", "SM", "GM", "MD"],
              activeLink: "/grievancesRegistration",
            },
          ],
        },

        {
          type: "groupHeader",
          groupTitle: "MD MODERATION",
          permissions: ["admin", "MD"],
        },
        {
          id: "MDmentors",
          title: "Mentors",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink: "/mentorRegistration?pageNo=1&pageSize=10&moderation=MD",
              activeLink: "/mentorRegistration",
              permissions: ["admin", "MD"],
            },
          ],
        },
        {
          id: "MDincubators",
          title: "Incubators",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/incubatorsRegistration?pageNo=1&pageSize=10&moderation=MD",
              activeLink: "/incubatorsRegistration",
              permissions: ["admin", "MD"],
            },
          ],
        },
        {
          id: "MDpartner",
          title: "Partner",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/partnerRegistration?pageNo=1&pageSize=10&moderation=MD",
              activeLink: "/partnerRegistration",
              permissions: ["admin", "MD"],
            },
          ],
        },
        {
          id: "MDinvestor",
          title: "Investor",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/investorRegistration?pageNo=1&pageSize=10&moderation=MD",
              activeLink: "/investorRegistration",
              permissions: ["admin", "MD"],
            },
          ],
        },
        {
          id: "MDGrievances",
          title: "Grievances",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "MD"],
          children: [
            {
              id: "MDGrievances1",
              title: "Applications",
              type: "item",
              icon: <Icon.List size={20} />,
              navLink:
                "/grievancesRegistration?pageNo=1&pageSize=10&moderation=MD",
              permissions: ["admin", "MD"],
              activeLink: "/grievancesRegistration",
            },
          ],
        },
        {
          type: "groupHeader",
          groupTitle: "GM MODERATION",
          permissions: ["admin", "GM", "MD"],
        },
        {
          id: "Gmstartups",
          title: "Startups",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "GM"],
          children: [
            {
              id: "Gmregistrations",
              title: (
                <>
                  <span
                    style={{ width: "100%", float: "left" }}
                    onClick={() => this.testing("registrations", "GM")}
                  >
                    Registrations
                  </span>
                </>
              ),
              type: "item",
              navLink:
                "/gmstartup-registrations?pageNo=1&pageSize=10&moderation=GM",
              activeLink: "/gmstartup-registrations",
              permissions: ["admin", "GM"],
            },
            // {
            //   id: "Gmincentives",
            //   title: "Incentives",
            //   type: "item",
            //   navLink: "/receivedapplications",
            //   activeLink: "/receivedapplications",
            //   permissions: ["admin", "GM"],
            // },
            // {
            //   id: "Gmbooster",
            //   title: "Booster Kit",
            //   type: "item",
            //   navLink: "/receivedapplications",
            //   activeLink: "/receivedapplications",
            //   permissions: ["admin", "GM"],
            // },
            {
              id: "Gmbooster",
              title: "Booster Kit",
              type: "item",
              navLink: "/boosterKits?pageNo=1&pageSize=10&moderation=admin",
              activeLink: "/boosterKits",
              permissions: ["admin", "GM"],
            },
          ],
        },
        {
          id: "GMmentors",
          title: "Mentors",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink: "/mentorRegistration?pageNo=1&pageSize=10&moderation=GM",
              activeLink: "/mentorRegistration",
              permissions: ["admin", "GM", "MD"],
            },
          ],
        },
        {
          id: "GMincubators",
          title: "Incubators",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/incubatorsRegistration?pageNo=1&pageSize=10&moderation=GM",
              activeLink: "/incubatorsRegistration",
              permissions: ["admin", "GM", "MD"],
            },
          ],
        },
        {
          id: "GMpartner",
          title: "Partner",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/partnerRegistration?pageNo=1&pageSize=10&moderation=GM",
              activeLink: "/partnerRegistration",
              permissions: ["admin", "GM", "MD"],
            },
          ],
        },
        {
          id: "GMinvestor",
          title: "Investor",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/investorRegistration?pageNo=1&pageSize=10&moderation=GM",
              activeLink: "/investorRegistration",
              permissions: ["admin", "GM", "MD"],
            },
          ],
        },
        {
          id: "GMGrievances",
          title: "Grievances",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "GM", "MD"],
          children: [
            {
              id: "GMGrievances1",
              title: "Applications",
              type: "item",
              icon: <Icon.List size={20} />,
              navLink:
                "/grievancesRegistration?pageNo=1&pageSize=10&moderation=GM",
              permissions: ["admin", "GM", "MD"],
              activeLink: "/grievancesRegistration",
            },
          ],
        },
        {
          type: "groupHeader",
          groupTitle: "SM MODERATION",
          permissions: ["admin", "SM", "GM", "MD"],
        },
        {
          id: "Smstartups",
          title: "Startups",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "SM", "GM"],
          children: [
            {
              id: "Smregistrations",
              title: (
                <>
                  <span
                    style={{ width: "100%", float: "left" }}
                    onClick={() => this.testing("registrations", "SM")}
                  >
                    Registrations
                  </span>
                </>
              ),
              type: "item",
              navLink:
                "/smstartup-registrations?pageNo=1&pageSize=10&moderation=SM",
              activeLink: "/smstartup-registrations",
              permissions: ["admin", "SM", "GM"],
            },

            // {
            //   id: "Smincentives",
            //   title: "Incentives",
            //   type: "item",
            //   navLink: "/receivedapplications",
            //   activeLink: "/receivedapplications",
            //   permissions: ["admin", "SM"],
            // },
            // {
            //   id: "Smbooster",
            //   title: "Booster Kit",
            //   type: "item",
            //   navLink: "/receivedapplications",
            //   activeLink: "/receivedapplications",
            //   permissions: ["admin", "SM"],
            // },
            {
              id: "Smbooster",
              title: "Booster Kit",
              type: "item",
              navLink: "/boosterKits?pageNo=1&pageSize=10&moderation=admin",
              activeLink: "/boosterKits",
              permissions: ["admin", "SM"],
            },
          ],
        },
        {
          id: "SMmentors",
          title: "Mentors",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "SM", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink: "/mentorRegistration?pageNo=1&pageSize=10&moderation=SM",
              activeLink: "/mentorRegistration",
              permissions: ["admin", "SM", "GM", "MD"],
            },
          ],
        },
        {
          id: "SMincubators",
          title: "Incubators",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "SM", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/incubatorsRegistration?pageNo=1&pageSize=10&moderation=SM",
              activeLink: "/incubatorsRegistration",
              permissions: ["admin", "SM", "GM", "MD"],
            },
          ],
        },
        {
          id: "SMpartner",
          title: "Partner",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "SM", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/partnerRegistration?pageNo=1&pageSize=10&moderation=SM",
              activeLink: "/partnerRegistration",
              permissions: ["admin", "SM", "GM", "MD"],
            },
          ],
        },
        {
          id: "SMinvestor",
          title: "Investor",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "SM", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/investorRegistration?pageNo=1&pageSize=10&moderation=SM",
              activeLink: "/investorRegistration",
              permissions: ["admin", "SM", "GM", "MD"],
            },
          ],
        },
        {
          id: "SMGrievances",
          title: "Grievances",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "SM", "GM", "MD"],
          children: [
            {
              id: "SMGrievances1",
              title: "Applications",
              type: "item",
              icon: <Icon.List size={20} />,
              navLink:
                "/grievancesRegistration?pageNo=1&pageSize=10&moderation=SM",
              permissions: ["admin", "SM", "GM", "MD"],
              activeLink: "/grievancesRegistration",
            },
          ],
        },
        {
          type: "groupHeader",
          groupTitle: "ASSOCIATE MODERATION",
          permissions: ["admin", "Associate", "SM", "GM", "MD"],
        },
        {
          id: "Associatestartups",
          title: "Startups",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "Associate", "SM", "GM"],
          children: [
            {
              id: "Associateregistrations",
              title: (
                <>
                  <span
                    style={{ width: "100%", float: "left" }}
                    onClick={() => this.testing("registrations", "Associate")}
                  >
                    Registrations
                  </span>
                </>
              ),
              type: "item",
              navLink:
                "/associatestartup-registrations?pageNo=1&pageSize=10&moderation=Associate",
              activeLink: "/associatestartup-registrations",
              permissions: ["admin", "Associate", "SM", "GM"],
            },
            {
              id: "Associateincentive",
              title: "Incentives",
              type: "item",
              navLink:
                "/incentiveRegistration?pageNo=1&pageSize=10&moderation=Associate",
              activeLink: "/incentiveRegistration",
              permissions: ["admin", "Associate"],
            },
            // {
            //   id: "Associatebooster",
            //   title: "Booster Kit",
            //   type: "item",
            //   navLink: "/receivedapplications",
            //   activeLink: "/receivedapplications",
            //   permissions: ["admin", "Associate"],
            // },
            {
              id: "Associatebooster",
              title: "Booster Kit",
              type: "item",
              navLink: "/boosterKits?pageNo=1&pageSize=10&moderation=admin",
              activeLink: "/boosterKits",
              permissions: ["admin", "Associate"],
            },
          ],
        },
        {
          id: "Associatementors",
          title: "Mentors",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "Associate", "SM", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/mentorRegistration?pageNo=1&pageSize=10&moderation=Associate",
              activeLink: "/mentorRegistration",
              permissions: ["admin", "Associate", "SM", "GM", "MD"],
            },
          ],
        },
        {
          id: "Associateincubators",
          title: "Incubators",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "Associate", "SM", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/incubatorsRegistration?pageNo=1&pageSize=10&moderation=Associate",
              activeLink: "/incubatorsRegistration",
              permissions: ["admin", "Associate", "SM", "GM", "MD"],
            },
          ],
        },
        {
          id: "Associatepartner",
          title: "Partner",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "Associate", "SM", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/partnerRegistration?pageNo=1&pageSize=10&moderation=Associate",
              activeLink: "/partnerRegistration",
              permissions: ["admin", "Associate", "SM", "GM", "MD"],
            },
          ],
        },
        {
          id: "Associateinvestor",
          title: "Investor",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "Associate", "SM", "GM", "MD"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/investorRegistration?pageNo=1&pageSize=10&moderation=Associate",
              activeLink: "/investorRegistration",
              permissions: ["admin", "Associate", "SM", "GM", "MD"],
            },
          ],
        },
        {
          id: "AssociateGrievances",
          title: "Grievances",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["admin", "Associate", "SM", "GM", "MD"],
          children: [
            {
              id: "AssociateGrievances1",
              title: "Applications",
              type: "item",
              icon: <Icon.List size={20} />,
              navLink:
                "/grievancesRegistration?pageNo=1&pageSize=10&moderation=Associate",
              permissions: ["admin", "Associate", "SM", "GM", "MD"],
              activeLink: "/grievancesRegistration",
            },
          ],
        },

        {
          type: "groupHeader",
          groupTitle: currentUser == "admin" ? "USERS,ROLES & PERMISSIONS" : "",
          permissions: ["admin"],
        },

        {
          id: "Manageusers",
          title: "Manage users",
          type: "collapse",
          icon: <Icon.Users size={20} />,
          permissions: ["admin"],
          children: [
            {
              id: "portalusers",
              title: "Portal users",
              type: "item",
              navLink: "/PortalUserConfig?pageNo=1&pageSize=10",
              activeLink: "/portalUserConfig",
              permissions: ["admin"],
            },
            {
              id: "departmentusers",
              title: "Department Users",
              type: "item",
              navLink: "/DepartmentUserConfig?pageNo=1&pageSize=10",
              activeLink: "/DepartmentUsers",
              permissions: ["admin"],
            },
          ],
        },

        {
          type: "groupHeader",
          groupTitle: "FDA MODERATION",
          permissions: ["FDA"],
        },
        {
          id: "startups",
          title: "Startups",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["FDA"],
          children: [
            {
              id: "registrations",
              title: (
                <>
                  <span
                    style={{ width: "100%", float: "left" }}
                    onClick={() => this.testing("registrations", "admin")}
                  >
                    Registrations
                  </span>
                </>
              ),
              type: "item",
              navLink: "/startup-registrations?pageNo=1&pageSize=10",
              activeLink: "/startup-registrations",
              permissions: ["FDA"],
            },
            {
              id: "incentive",
              title: "Incentives",
              type: "item",
              navLink:
                "/incentiveRegistration?pageNo=1&pageSize=10&moderation=FDA",
              activeLink: "/incentiveRegistration",
              permissions: ["FDA"],
            },
            {
              id: "booster",
              title: "Booster Kit",
              type: "item",
              navLink: "/boosterKits?pageNo=1&pageSize=10&moderation=FDA",
              activeLink: "/boosterKits",
              permissions: ["FDA"],
            },
          ],
        },
        {
          id: "mentors",
          title: "Mentors",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["FDA"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/mentorRegistration?pageNo=1&pageSize=10&moderation=FDA",
              activeLink: "/mentorRegistration",
              permissions: ["FDA", "Associate"],
            },
          ],
        },
        {
          id: "incubators",
          title: "Incubators",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["FDA"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/incubatorsRegistration?pageNo=1&pageSize=10&moderation=FDA",
              activeLink: "/incubatorsRegistration",
              permissions: ["FDA", "Associate"],
            },
          ],
        },
        {
          id: "partner",
          title: "Partner",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["FDA"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/partnerRegistration?pageNo=1&pageSize=10&moderation=FDA",
              activeLink: "/partnerRegistration",
              permissions: ["FDA", "Associate"],
            },
          ],
        },
        {
          id: "Investor",
          title: "Investor",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["FDA"],
          children: [
            {
              id: "registration",
              title: "Registration",
              type: "item",
              navLink:
                "/investorRegistration?pageNo=1&pageSize=10&moderation=FDA",
              activeLink: "/investorRegistration",
              permissions: ["FDA", "Associate"],
            },
          ],
        },
        {
          id: "FDAGrievances",
          title: "Grievances",
          type: "collapse",
          icon: <Icon.List size={20} />,
          permissions: ["FDA"],
          children: [
            {
              id: "FDAGrievances1",
              title: "Applications",
              type: "item",
              icon: <Icon.List size={20} />,
              navLink:
                "/grievancesRegistration?pageNo=1&pageSize=10&moderation=FDA",
              permissions: ["FDA", "Associate", "SM", "GM", "MD"],
              activeLink: "/grievancesRegistration",
            },
          ],
        },
      ];

      this.setState({
        navigationConfig,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths);
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      );
    }
  }

  render() {
    // Loop over sidebar items
    // eslint-disable-next-line
    const menuItems = this.state.navigationConfig.map((item) => {
      const CustomAnchorTag = item.type === "external-link" ? `a` : Link;
      // checks if item has groupheader
      if (
        item.type === "groupHeader" &&
        item.permissions &&
        item.permissions.includes(currentUser)
      ) {
        return (
          <li
            className="navigation-header"
            key={`group-header-${item.groupTitle}`}
          >
            <span>{item.groupTitle}</span>
          </li>
        );
      }

      let renderItem = (
        <li
          className={classnames("nav-item", {
            "has-sub": item.type === "collapse",
            open: this.state.activeGroups.includes(item.id),
            "sidebar-group-active": this.state.currentActiveGroup.includes(
              item.id
            ),
            hover: this.props.hoverIndex === item.id,
            active:
              ((this.props.activeItemState === item.navLink ||
                this.props.activeItemState.includes(item.activeLink)) &&
                item.type === "item") ||
              (item.parentOf &&
                item.parentOf.includes(this.props.activeItemState)),
            disabled: item.disabled,
          })}
          key={item.id}
          onClick={(e) => {
            e.stopPropagation();
            if (item.type === "item") {
              this.props.handleActiveItem(item.navLink);
              this.handleGroupClick(item.id, null, item.type);
              if (this.props.deviceWidth <= 1200 && item.type === "item") {
                this.props.toggleMenu();
              }
            } else {
              this.handleGroupClick(item.id, null, item.type);
            }
          }}
        >
          <CustomAnchorTag
            to={
              item.filterBase
                ? item.filterBase
                : item.navLink && item.type === "item"
                ? item.navLink
                : ""
            }
            href={item.type === "external-link" ? item.navLink : ""}
            className={`d-flex ${
              item.badgeText
                ? "justify-content-between"
                : "justify-content-start"
            }`}
            onMouseEnter={() => {
              this.props.handleSidebarMouseEnter(item.id);
            }}
            onMouseLeave={() => {
              this.props.handleSidebarMouseEnter(item.id);
            }}
            key={item.id}
            onClick={(e) => {
              return item.type === "collapse" ? e.preventDefault() : "";
            }}
            target={item.newTab ? "_blank" : undefined}
          >
            <div className="menu-text">
              {item.icon}
              <span className="menu-item menu-title">{item.title} </span>
            </div>
            <ChevronRight />

            {item.badge ? (
              <div className="menu-badge">
                <Badge color={item.badge} className="mr-1" pill>
                  {item.badgeText}
                </Badge>
              </div>
            ) : (
              ""
            )}
            {
              item.type === "collapse"
              // <ChevronRight className="menu-toggle-icon" size={13} />
            }
          </CustomAnchorTag>
          {item.type === "collapse" ? (
            <SideMenuGroup
              group={item}
              handleGroupClick={this.handleGroupClick}
              activeGroup={this.state.activeGroups}
              handleActiveItem={this.props.handleActiveItem}
              activeItemState={this.props.activeItemState}
              handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
              activePath={this.props.activePath}
              hoverIndex={this.props.hoverIndex}
              initRender={this.initRender}
              parentArr={this.parentArr}
              triggerActive={undefined}
              currentActiveGroup={this.state.currentActiveGroup}
              permission={this.props.permission}
              currentUser={currentUser}
              redirectUnauthorized={this.redirectUnauthorized}
              collapsedMenuPaths={this.props.collapsedMenuPaths}
              toggleMenu={this.props.toggleMenu}
              deviceWidth={this.props.deviceWidth}
            />
          ) : (
            ""
          )}
        </li>
      );

      if (
        item.navLink &&
        item.collapsed !== undefined &&
        item.collapsed === true
      ) {
        this.collapsedPath = item.navLink;
        this.props.collapsedMenuPaths(item.navLink);
      }
      if (
        (item.type === "collapse" &&
          item.permissions &&
          item.permissions.includes(currentUser)) ||
        item.type === "external-link" ||
        (item.type === "item" &&
          item.permissions &&
          item.permissions.includes(currentUser)) ||
        item.permissions === undefined
      ) {
        return renderItem;
      } else if (
        item.type === "item" &&
        item.navLink === this.props.activePath &&
        !item.permissions.includes(currentUser)
      ) {
        return this.redirectUnauthorized();
      }
    });
    return <React.Fragment>{menuItems}</React.Fragment>;
  }
}
export default SideMenuContent;
