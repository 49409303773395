import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
import classnames from "classnames";
import usericon from "../../../assets/img/images/user-icon.png";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";

import { history } from "../../../history";

const UserDropdown = (props) => {
  const logoutUser = () => {
    localStorage.clear();
    localStorage.setItem("SK-SESSION", "logout");
    history.push("/");
  };

  return (
    <div className="drop_dwn_usertg">
      <DropdownMenu right>
        <div className="eeeeeeeeee">
          <DropdownItem onClick={logoutUser} className="logout-btn">
            <Icon.Power size={14} className="mr-50" />
            <span className="align-middle">Log Out</span>
          </DropdownItem>
        </div>
      </DropdownMenu>
    </div>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div
              className="user-nav"
              style={{ width: "100%", position: "relative", top: "14px" }}
            >
              <div
                className="user-status"
                style={{ position: "absolute", right: "64px" }}
              >
                <span
                  className="user-name  text-bold-600"
                  // style={{ position: "relative", right: "63px" }}
                >
                  {this.props.userName.toLowerCase()}
                </span>
                {localStorage.getItem("adminaccess")}
              </div>
              <span
                data-tour="user d-inline"
                style={{ position: "absolute", right: "19px", top: "0px" }}
              >
                <img
                  src={this.props?.user?.profileURL || usericon}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
                <svg
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                  className="caret-down-nav-icon"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 10l5 5 5-5z" />
                </svg>
              </span>
            </div>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default NavbarUser;
