import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  investor: [],
  isLoading: false,
  isFileUploading: false,
  logs: [],
  isLogsLoading: false,
  selectedInvestor: {},
  totalInvestor: 0,
  assignableUsers: [],
};

const InvestorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_INVESTOR_LIST:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_INVESTOR_LIST:
      return {
        ...state,
        isLoading: false,
        investor: action.payload.investorData,
        totalInvestor: action.payload.count,
      };
    case actionTypes.GET_INVESTOR_DETAILS:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_INVESTOR_DETAILS:
      return { ...state, isLoading: false, selectedInvestor: action.payload };
    case actionTypes.CLEAR_INVESTOR_DETIALS:
      return { ...state, selectedInvestor: {} };
    case actionTypes.GET_ASSIGNABLE_INVESTOR_USERS:
      return { ...state, assignableUsers: action.payload };
    case actionTypes.GET_INVESTOR_LOGS:
      return { ...state, isLogsLoading: true };
    case actionTypes.FETCH_INVESTOR_LOGS:
      return { ...state, isLogsLoading: false, logs: action.payload };

    default:
      return { ...state };
  }
};

export default InvestorReducer;
