import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  mentors: [],
  isLoading: false,
  isFileUploading: false,
  logs: [],
  isLogsLoading: false,
  selectedMentor: {},
  totalMentors: 0,
  assignableUsers: [],
};

const MentorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_MENTOR_LIST:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_MENTOR_LIST:
      return {
        ...state,
        isLoading: false,
        mentors: action.payload.mentorData,
        totalMentors: action.payload.count,
      };
    case actionTypes.GET_MENTOR_DETAILS:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_MENTOR_DETAILS:
      return { ...state, isLoading: false, selectedMentor: action.payload };
    case actionTypes.CLEAR_MENTOR_DETIALS:
      return { ...state, selectedMentor: {} };
    case actionTypes.GET_ASSIGNABLE_USERS:
      return { ...state, assignableUsers: action.payload };
    case actionTypes.GET_MENTOR_LOGS:
      return { ...state, isLogsLoading: true };
    case actionTypes.FETCH_MENTOR_LOGS:
      return { ...state, isLogsLoading: false, logs: action.payload };

    default:
      return { ...state };
  }
};

export default MentorsReducer;
