import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  incentives: [],
  isLoading: false,
  isFileUploading: false,
  logs: [],
  isLogsLoading: false,
  totalIncentives: 0,
  incentiveDetails: {},
};

const BoosterKitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_INCENTIVE_LIST:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_INCENTIVE_LIST:
      return {
        ...state,
        isLoading: false,
        incentives: action.payload.startupData,
        totalIncentives: action.payload.count,
      };
    case actionTypes.FETCH_INCENTIVE_DETAILS:
      return { ...state, isLoading: false, incentiveDetails: action.payload };
    case actionTypes.GET_INCENTIVE_DETAILS:
      return { ...state, isLoading: true };
    default:
      return { ...state };
  }
};

export default BoosterKitsReducer;
