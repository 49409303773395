import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Disc, X, Circle } from "react-feather";
import classnames from "classnames";
import logoImage from "./../../../../assets/img/pages/logo-bird.png";
import "./../../../../assets/scss/components/logo.scss";

class SidebarHeader extends Component {
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow,
    } = this.props;
    return (
      <div className="navbar-header pl-1">
        <ul className="nav navbar-nav flex-row flex-wrap">
          <li className="nav-item mr-auto">
            <NavLink to="/" className="navbar-brand">
              {/* <div className="brand-logo" /> */}
              <img
                src={logoImage}
                // style={{width:"39%" , height:"auto"}}
                className="main_logo"
                id="logoImage"
              />
              <h2 className="brand-text mb-0 ">
                STARTUP
                <span className="d-block" style={{ fontSize: "initial" }}>
                  KARNATAKA
                </span>
              </h2>
            </NavLink>
          </li>
          <li className="nav-item nav-toggle d-inline">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                <Disc
                  onClick={() => {
                    toggleSidebarMenu(true);
                    toggle();
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark",
                    }
                  )}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                <Circle
                  onClick={() => {
                    toggleSidebarMenu(false);
                    toggle();
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark",
                    }
                  )}
                  size={20}
                />
              )}
              <X
                onClick={sidebarVisibility}
                className={classnames(
                  "toggle-icon icon-x d-block d-xl-none font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark",
                  }
                )}
                size={20}
              />
            </div>
          </li>
        </ul>
        {/* <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        /> */}
      </div>
    );
  }
}

export default SidebarHeader;
