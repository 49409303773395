//Action Types for Manage Calls
export const SET_TOKEN = "SET_TOKEN";
export const GET_CALLS = "GET_CALLS";
export const FETCH_CALLS = "FETCH_CALLS";
export const CREATE_CALL = "CREATE_CALL";
export const UPDATE_CALL = "UPDATE_CALL";
export const DELETE_CALL = "DELETE_CALL";
export const FILTER_CALLS = "FILTER_CALLS";
export const FETCH_FILTER = "FETCH_FILTER";

//Action Types for Dashboard
export const GET_ANALYTICS = "GET_ANALYTICS";
export const FETCH_ANALYTICS = "FETCH_ANALYTICS";

//Action Types for Stages
export const GET_APPROVED_LIST = "GET_APPROVED_LIST";
export const FETCH_APPROVED_LIST = "FETCH_APPROVED_LIST";
export const DOWNLOAD_TEMPLATE = "DOWNLOAD_TEMPLATE";
export const EXPORT_TO_EXCEL = "EXPORT_TO_EXCEL";

export const UPLOAD_WINNER_LIST = "UPLOAD_WINNER_LIST";
export const UPLOAD_WINNER_LIST_COMPLETE = "UPLOAD_WINNER_LIST_COMPLETE";
export const CLEAR_APPLICATIONS = "CLEAR_APPLICATIONS";
export const GET_LOGS = "GET_LOGS";
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";

//Action Types for Roles and Permission
export const GET_ROLES_PERMISSIONS = "GET_ROLES_PERMISSIONS";
export const FETCH_ROLES_PERMISSIONS = "FETCH_ROLES_PERMISSIONS";
export const UPDATE_ROLES_PERMISSIONS = "UPDATE_ROLES_PERMISSIONS";
export const UPDATE_ROLES_PERMISSIONS_SUCCESS =
  "UPDATE_ROLES_PERMISSIONS_SUCCESS";

//Action Types for Mentors
export const GET_MENTOR_LIST = "GET_MENTOR_LIST";
export const LOADING_MENTOR_LIST = "LOADING_MENTOR_LIST";
export const FETCH_MENTOR_LIST = "FETCH_MENTOR_LIST";
export const GET_MENTOR_DETAILS = "GET_MENTOR_DETAILS";
export const FETCH_MENTOR_DETAILS = "FETCH_MENTOR_DETAILS";
export const CLEAR_MENTOR_DETIALS = "CLEAR_MENTOR_DETIALS";
export const GET_ASSIGNABLE_USERS = "GET_ASSIGNABLE_USERS";
export const APPROVE_MENTOR_APPLICATION = "APPROVE_MENTOR_APPLICATION";
export const GET_MENTOR_LOGS = "GET_MENTOR_LOGS";
export const FETCH_MENTOR_LOGS = "FETCH_MENTOR_LOGS";
export const ASSIGN_MENTOR_APPLICATION = "ASSIGN_MENTOR_APPLICATION";

//Action Types for Incubators
export const GET_INCUBATORS_LIST = "GET_INCUBATORS_LIST";
export const LOADING_INCUBATORS_LIST = "LOADING_INCUBATORS_LIST";
export const FETCH_INCUBATORS_LIST = "FETCH_INCUBATORS_LIST";
export const GET_INCUBATORS_DETAILS = "GET_INCUBATORS_DETAILS";
export const FETCH_INCUBATORS_DETAILS = "FETCH_INCUBATORS_DETAILS";
export const CLEAR_INCUBATORS_DETIALS = "CLEAR_INCUBATORS_DETIALS";
export const APPROVE_INCUBATOR_APPLICATION = "APPROVE_INCUBATOR_APPLICATION";
export const GET_INCUBATOR_LOGS = "GET_INCUBATOR_LOGS";
export const FETCH_INCUBATOR_LOGS = "FETCH_INCUBATOR_LOGS";
export const GET_ASSIGNABLE_INCUBATOR_USERS = "GET_ASSIGNABLE_INCUBATOR_USERS";
export const ASSIGN_INCUBATOR_APPLICATION = "ASSIGN_INCUBATOR_APPLICATION";

//Action Types for Investor
export const GET_INVESTOR_LIST = "GET_INVESTOR_LIST";
export const LOADING_INVESTOR_LIST = "LOADING_INVESTOR_LIST";
export const FETCH_INVESTOR_LIST = "FETCH_INVESTOR_LIST";
export const GET_INVESTOR_DETAILS = "GET_INVESTOR_DETAILS";
export const FETCH_INVESTOR_DETAILS = "FETCH_INVESTOR_DETAILS";
export const CLEAR_INVESTOR_DETIALS = "CLEAR_INVESTOR_DETIALS";
export const ASSIGN_INVESTOR_APPLICATION = "ASSIGN_INVESTOR_APPLICATION";
export const APPROVE_INVESTOR_APPLICATION = "APPROVE_INVESTOR_APPLICATION";
export const GET_INVESTOR_LOGS = "GET_INVESTOR_LOGS";
export const FETCH_INVESTOR_LOGS = "FETCH_INVESTOR_LOGS";
export const GET_ASSIGNABLE_INVESTOR_USERS = "GET_ASSIGNABLE_INVESTOR_USERS";

//Action Types for Partner
export const GET_PARTNER_LIST = "GET_PARTNER_LIST";
export const LOADING_PARTNER_LIST = "LOADING_PARTNER_LIST";
export const FETCH_PARTNER_LIST = "FETCH_PARTNER_LIST";
export const GET_PARTNER_DETAILS = "GET_PARTNER_DETAILS";
export const FETCH_PARTNER_DETAILS = "FETCH_PARTNER_DETAILS";
export const CLEAR_PARTNER_DETIALS = "CLEAR_PARTNER_DETIALS";
export const ASSIGN_PARTNER_APPLICATION = "ASSIGN_PARTNER_APPLICATION";
export const APPROVE_PARTNER_APPLICATION = "APPROVE_PARTNER_APPLICATION";
export const GET_PARTNER_LOGS = "GET_PARTNER_LOGS";
export const FETCH_PARTNER_LOGS = "FETCH_PARTNER_LOGS";
export const GET_ASSIGNABLE_PARTNER_USERS = "GET_ASSIGNABLE_PARTNER_USERS";

//Action Types for Grievances
export const GET_GRIEVANCES_LIST = "GET_GRIEVANCES_LIST";
export const LOADING_GRIEVANCES_LIST = "LOADING_GRIEVANCES_LIST";
export const FETCH_GRIEVANCES_LIST = "FETCH_GRIEVANCES_LIST";
export const GET_GRIEVANCES_DETAILS = "GET_GRIEVANCES_DETAILS";
export const FETCH_GRIEVANCES_DETAILS = "FETCH_GRIEVANCES_DETAILS";
export const CLEAR_GRIEVANCES_DETIALS = "CLEAR_GRIEVANCES_DETIALS";
// export const GET_ASSIGNABLE_USERS = "GET_ASSIGNABLE_USERS";
export const APPROVE_GRIEVANCES_APPLICATION = "APPROVE_GRIEVANCES_APPLICATION";
export const GET_GRIEVANCES_LOGS = "GET_GRIEVANCES_LOGS";
export const FETCH_GRIEVANCES_LOGS = "FETCH_GRIEVANCES_LOGS";
export const ASSIGN_GRIEVANCES_APPLICATION = "ASSIGN_GRIEVANCES_APPLICATION";

//Action Types for Booster Kits
export const GET_BOOSTERKIT_LIST = "GET_BOOSTERKIT_LIST";
export const LOADING_BOOSTERKIT_LIST = "LOADING_BOOSTERKIT_LIST";
export const FETCH_BOOSTERKIT_LIST = "FETCH_BOOSTERKIT_LIST";

//Action Types for Incentives
export const GET_INCENTIVE_LIST = "GET_INCENTIVE_LIST";
export const LOADING_INCENTIVE_LIST = "LOADING_INCENTIVE_LIST";
export const FETCH_INCENTIVE_LIST = "FETCH_INCENTIVE_LIST";
export const GET_INCENTIVE_DETAILS = "GET_INCENTIVE_DETAILS";
export const FETCH_INCENTIVE_DETAILS = "FETCH_INCENTIVE_DETAILS";
export const APPROVE_INCENTIVE_APPLICATION = "APPROVE_INCENTIVE_APPLICATION";
